<template>
  <AView
    :value="value"
    :actions="actions"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onHover="onHover"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @action="(action) => action.handler({ from: value, service })"
    @onServiceSave="onSave"
  >
    <template v-slot:content="{}">
      <iframe
        id="ytplayer"
        type="text/html"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/M7lc1UVf-VE?autoplay=1&origin=http://example.com"
        frameborder="0"
      ></iframe>
    </template>
  </AView>
</template>
  
  
  
  
  <script>
import AView from "../../../../../components/wad/organisms/layout/A-View.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    AView,
  },
  data() {
    return {
      actions: [],
    };
  },
  methods: {
    onHover() {
      // this.$store.dispatch(
      //   "CustomerStore/SetActiveCustomer",
      //   this.getCustomer()
      // );
    },
  },
};
</script>